import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const sendForm = (event) => {
  event.preventDefault();
  const elements = event.target.elements;
  const data = {
    message: elements.message.value,
    name: elements.name.value,
    email: elements.email.value,
    phone: elements.phone.value
  }
  fetch('https://app.otoschool.fr:8088/contact', {
    headers: {"Content-type": "application/json"},
    body: JSON.stringify(data),
    method: "POST"
  }).then(() => {
    window.location.href = "/contact";
  })
}

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <section className="contact-form">
      <h1 style={{
        backgroundImage: `linear-gradient(to bottom, #9ff8e7, #9febfc)`,
        padding: `50px 0`
      }}>Contactez-nous</h1>
      <div style={{
          width: `80%`,
          margin: `auto`,
        }}>
      <form method="POST" action="#" onSubmit={sendForm}>
      <div className="w3-half w3-container">
        <input type="text" name="name" placeholder="Nom" className="w3-input w3-round"/>
        <input type="text" name="email" placeholder="Email" className="w3-input w3-round"/>
        <input type="text" name="phone" placeholder="Téléphone" className="w3-input w3-round"/>
      </div>
      <div className="w3-half w3-container">
        <textarea name="message" placeholder="Message" rows="10" className="w3-input w3-round">
        </textarea>
        <button style={{
          backgroundColor: `#3BA2FC`,
          color: `#ffffff`,
          border: `none`,
          fontSize: `1rem`,
          fontWeight: `bold`,
          cursor: `pointer`,
          borderRadius: `5px`,
          padding: `5px 50px`,
          float: `left`,
          margin: `15px 0`
        }}>
          Envoyer
        </button>
      </div>
      </form>
      <p>&nbsp;</p>
      </div>
    </section>
  </Layout>
)

export default ContactPage
